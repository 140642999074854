<template>
    <!-- 参训教师--作品评选 -->
    <div class="activity-stage-detail manage-wrapper">
        <div class="stage-container">
            <!-- 基础信息 -->
            <div class="activity-info-wrapper flex-layout">
                <img :src="downloadURL + activityInfo.cover" alt="" class="activity-cover"
                    :onerror="$store.state.course.defaultActivityBigCover">
                <div class="activity-info">
                    <h3 class="activity-title">{{activityInfo.name || '无'}}</h3>
                    <p class="activity-info-item"><strong>所属项目</strong>：{{currentProject.name}}</p>
                    <p class="activity-info-item"><strong>活动时间</strong>：{{activityInfo.activityBeginTime}} —
                        {{activityInfo.activityEndTime}}</p>
                    <p class="activity-info-item last-item wes-3">
                        <strong>活动简介</strong>：{{activityInfo.introduction || '暂无简介'}}</p>
                    <button class="to-rule csp"
                        @click="$router.push(`/activity/rule/${$route.params.id}?breadNumber=5`)">活动规则</button>
                </div>
            </div>
            <div v-if="!showEmpty">
                <!-- 筛选条件 -->
                <div class="group-wrapper">
                    <el-tabs v-model="currentTab" :stretch="true" @click="currentTab = tab.title">
                        <el-tab-pane :label="tab.name" :name="tab.title" v-for="tab in tabList" :key="tab.title">
                            <div class="sort-wrapper" v-if="currentTab != 'detail'">
                                <div class="sort-row flex-layout">
                                    <span class="sort-row-name">活动环节：</span>
                                    <ul class="sort-list">
                                        <li v-for="item in stageList" :key="item.id"
                                            :class="{active:activeStage == item.id}" @click="activeStage = item.id">
                                            {{item.name}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="sort-row flex-layout">
                                    <span class="sort-row-name" v-html="'角&emsp;&emsp;色：'"></span>
                                    <ul class="sort-list">
                                        <li v-for="item in roleList" :key="item.id"
                                            :class="{active:activeRole == item.id}" @click="activeRole = item.id">
                                            {{item.name}}</li>
                                    </ul>
                                </div>
                                <div class="sort-row flex-layout">
                                    <span class="sort-row-name" v-html="'班&emsp;&emsp;级：'"></span>
                                    <ul class="sort-list">
                                        <li v-for="item in classList" :key="item.id"
                                            :class="{active:activeClass == item.id}" @click="activeClass = item.id">
                                            {{item.name}}
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <component class="tab" v-bind:is="currentTabComponent" :stageId="activeStage" :classId="activeClass"
                    :typeId="activeType" :roleId="activeRole"></component>
            </div>
            <Empty :show="showEmpty"></Empty>
        </div>

    </div>
</template>

<script>
    import {
        mapState
    } from "vuex";
    import matchSelection from "./component/activity_selection_part.vue"
    export default {
        components: {
            'match-selection': matchSelection,
        },
        data() {
            return {
                showEmpty: undefined,
                activityInfo: {},
                currentTab: 'selection',
                tabList: [{
                    title: "selection",
                    name: '作品评选'
                }, ],
                activeStage: null,
                stageList: [],
                activeGrade: null,
                gradeList: [],
                activeClass: null,
                classList: [],
                activeType: null,
                typeList: [],
                activeRole: null,
                roleList: [],
            }
        },
        computed: {
            currentTabComponent: function () {
                this.getStageSortList();
                return "match-" + this.currentTab;
            },
            ...mapState({
                currentProject: state => state.user.currentProject,
                userRole: state => state.user.role
            }),
        },
        methods: {
            async getStageSortList() {
                let resData = await this.$Api.Activity.getStageSortList(this.$route.params.id);
                // console.log('筛选列表信息', resData);
                this.stageList = resData.data.paramStageList;
                this.activeStage = this.stageList.length > 0 ? this.stageList[0].id : null;
                // this.typeList = resData.data.paramTypeList;
                // this.activeType = this.typeList.length > 0 ? this.typeList[0].id : null;
                // this.gradeList = resData.data.paramGradeList;
                // this.activeGrade = this.gradeList.length > 0 ? this.gradeList[0].id : null
                this.classList = resData.data.paramClassList;
                this.activeClass = this.classList.length > 0 ? this.classList[0].id : null
                this.roleList = resData.data.paramRoleList;
                this.activeRole = this.roleList.length > 0 ? this.roleList[0].id : null
            },
            async getActivityInfo() {
                let resData = await this.$Api.Activity.getActivityDetail(this.$route.params.id)
                // console.log(resData);
                if (resData.code == 200) {
                    this.activityInfo = resData.data
                }
            },
            async getStageList() {
                let params = {
                    activityId: this.$route.params.id,
                    page: 1,
                    limit: 5
                }
                let resData = await this.$Api.Activity.getStageList(params)
                // console.log('子活动列表', resData);
                if (resData.code != 200 || resData.data.list.length == 0) {
                    return new Promise((resolve, reject) => {
                        resolve(true)
                    })
                }
                if (resData.code == 200 && resData.data.list.length > 0) {
                    return new Promise((resolve, reject) => {
                        resolve(false)
                    })
                }
            },
        },
        mounted() {
            this.getActivityInfo();
        }
    }
</script>

<style lang="less" scoped>
    .activity-stage-detail {
        .activity-info-wrapper {
            margin-bottom: 19px;
            border: 1px solid #f2f2f2;
            border-radius: 10px;
            padding: 38px 27px 40px;
            background-color: #fff;
            line-height: 1;

            .activity-info {
                overflow: hidden;
                flex: 1;
                position: relative;
            }

            .activity-cover {
                margin-right: 36px;
                width: 361px;
                height: 215px;
                flex-shrink: 0;
                border-radius: 10px;
            }

            .activity-title {
                font-size: 13px;
                font-weight: 600;
                margin: 12px 0 19px;
            }

            .activity-info-item {
                color: #999;
                margin-bottom: 19px;
                font-size: 12px;

                &.last-item {
                    margin-bottom: 33px;
                    line-height: 20px;
                }

                strong {
                    font-weight: 600;
                }
            }

            .to-rule {
                border-radius: 5px;
                padding: 0 20px;
                line-height: 27px;
                color: #fff;
                letter-spacing: 1px;
                font-size: 12px;
                background-color: #508EF9;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }

        .group-wrapper {
            margin-bottom: 19px;
            box-sizing: border-box;
            padding: 0 27px 0;
            overflow-y: auto;
            border: 1px solid #f2f2f2;
            border-radius: 8px;
            background-color: #fff;

            /deep/ .el-tabs__header {
                margin-bottom: 11px !important;

                .el-tabs__item {
                    padding: 0 20px !important;
                    font-size: 13px;
                    color: #555555;
                    height: 44px;
                    line-height: 44px;

                }

                .el-tabs__nav-wrap.is-top {
                    padding: 0 13px;
                }

                .el-tabs__item:hover,
                .el-tabs__item.is-active {
                    color: #508EF9;
                }
            }

            /deep/ .el-tabs__active-bar {
                height: 1px;
                background-color: #508EF9;
                padding: 0 20px;
                margin-left: -20px;
            }

            /deep/ .el-tabs__nav-wrap::after {
                height: 1px;
                background-color: #f2f2f2;
            }

            /deep/ .el-tabs__nav.is-stretch>* {
                flex: 0 0 15%;
            }

            .sort-row {
                padding: 0 31px;
                line-height: 46px;
                border-bottom: 1px dashed rgba(191, 191, 191, .12);

                &:last-child {
                    border-bottom: 0;
                }
            }

            .sort-row-name {
                color: #555;
                flex-shrink: 0;
                font-size: 12px;
            }

            .sort-list li {
                display: inline-block;
                margin-left: 40px;
                cursor: pointer;
                color: #999;
                font-size: 12px;

                &.active {
                    color: #508EF9;
                }
            }
        }

        @media screen and (min-width:1250px) {
            .activity-info-wrapper {
                margin-bottom: 23px;
                padding: 46px 33px 49px;


                .activity-cover {
                    margin-right: 43px;
                    width: 437px;
                    height: 261px;
                }

                .activity-title {
                    font-size: 16px;
                    margin: 14px 0 23px;
                }

                .activity-info-item {
                    margin-bottom: 23px;

                    &.last-item {
                        margin-bottom: 39px;
                        line-height: 24px;
                    }
                }

                .to-rule {
                    padding: 0 24px;
                    line-height: 34px;
                    font-size: 15px;
                }
            }

            .group-wrapper {
                margin-bottom: 23px;
                padding: 0 33px 0;

                /deep/ .el-tabs__header {

                    .el-tabs__item {
                        font-size: 16px;
                        height: 53px;
                        line-height: 53px;

                    }
                }


                .sort-row {
                    padding: 0 38px;
                    line-height: 56px;
                }

                .sort-row-name {
                    font-size: 14px;
                }

                .sort-list li {
                    margin-left: 50px;
                    font-size: 14px;
                }
            }
        }
    }
</style>